import React, { ReactNode } from "react";
import { COLORS } from "@mapsy/shared";
import { alpha, Box, Stack, Typography } from "@mui/material";
import { UserIcon } from "assets/navbarIcons/UserIcon";

interface Props {
  color?: string;
  Icon?: React.FC<{ fill?: string }>;
  title: string;
  description: ReactNode;
}

export const OverviewInfoCard: React.FC<Props> = ({
  color = COLORS.BLUE_1,
  title = "Title",
  description = "Description",
  Icon = UserIcon,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        px: { md: 2, xs: 2 },
        py: { md: 2, xs: 1 },
        gap: 2,
        backgroundColor: "#fff",
        borderRadius: "18px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundColor: alpha(color, 0.25),
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "50px", xs: "35px" },
          height: { md: "50px", xs: "35px" },
        }}
      >
        <Icon fill={color} />
      </Box>
      <Stack sx={{ justifyContent: "space-around" }}>
        <Typography sx={{ color: "#718EBF" }} variant="caption" component="p">
          {title}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "#232323", fontWeight: 600 }}
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  );
};
