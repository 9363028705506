// Tomado de https://tailgrids.com/components/datepicker
import { Box, Button, Input, Typography } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import React, { useState, useEffect, useRef, useMemo } from "react";

export default function MapsyDatePicker({ label, placeholder, value, onChange, name }: any) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(value[0] ? (new Date(value[0])).toLocaleDateString("en-US") : null);
  const [selectedEndDate, setSelectedEndDate] = useState(value[1] ? (new Date(value[1])).toLocaleDateString("en-US") : null);
  const [isOpen, setIsOpen] = useState(false);

  const datepickerInputRef = useRef<HTMLElement>(null);

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      daysArray.push(<div key={`empty-${i}`}></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(year, month, i);
      const dayString = day.toLocaleDateString("en-US");
      const additionalSx = {} as any;

      if (selectedStartDate && dayString === selectedStartDate) {
        additionalSx.backgroundColor = "rgb(55, 88, 249)";
        additionalSx.color = "rgb(255, 255, 255)";
        additionalSx.borderTopRightRadius = "0px";
        additionalSx.borderBottomRightRadius = "0px";
      }
      if (selectedEndDate && dayString === selectedEndDate) {
        additionalSx.backgroundColor = "rgb(55, 88, 249)";
        additionalSx.color = "rgb(255, 255, 255)";
        additionalSx.borderTopLeftRadius = "0px";
        additionalSx.borderBottomLeftRadius = "0px";
      }
      if (
        selectedStartDate &&
        selectedEndDate &&
        new Date(day) > new Date(selectedStartDate) &&
        new Date(day) < new Date(selectedEndDate)
      ) {
        additionalSx.backgroundColor = "rgb(243, 244, 246)";
        additionalSx.borderColor = "rgb(255, 255, 255)";
        additionalSx.borderRadius = "0px";
      }

      daysArray.push(
        <Box
          sx={{
            display: "flex",
            height: "38px",
            width: "38px",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(17, 25, 40)",
            borderWidth: "0.5px",
            borderRadius: "9999px",
            mb: 1,
            ":hover": {
              backgroundColor: "rgb(243, 228, 234)",
              cursor: "pointer",
              borderColor: "rgb(223, 228, 234)"
            },
            ...additionalSx
          }}
          key={i}
          data-date={dayString}
          onClick={() => handleDayClick(dayString)}
        >
          {i}
        </Box>,
      );
    }

    return daysArray;
  };

  const handleDayClick = (selectedDay: any) => {
    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      setSelectedStartDate(selectedDay);
      setSelectedEndDate(null);
    } else {
      if (new Date(selectedDay) < new Date(selectedStartDate)) {
        setSelectedEndDate(selectedStartDate);
        setSelectedStartDate(selectedDay);
      } else {
        setSelectedEndDate(selectedDay);
      }
    }
  };

  const innerValue = useMemo(() => {
    if (selectedStartDate && selectedEndDate) {
      return `${selectedStartDate} - ${selectedEndDate}`;
    } else if (selectedStartDate) {
      return selectedStartDate;
    } else {
      return "";
    }
  }, [selectedStartDate, selectedEndDate]);

  const toggleDatepicker = () => {
    setIsOpen(!isOpen);
  };

  const handleApply = () => {
    setIsOpen(false);

    if (selectedStartDate && selectedEndDate) {
      onChange?.([(new Date(selectedStartDate)).toISOString(), (new Date(selectedEndDate)).toISOString()], datepickerInputRef?.current)
    } else if (selectedStartDate) {
      onChange?.([(new Date(selectedStartDate)).toISOString(), null], datepickerInputRef?.current)
    } else if (selectedEndDate) {
      onChange?.([null, (new Date(selectedEndDate)).toISOString()], datepickerInputRef?.current)
    } else {
      onChange?.([], datepickerInputRef?.current)
    }
  };

  const handleCancel = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    onChange?.([], datepickerInputRef?.current)
    setIsOpen(false);
  };

  return (
    <Box component="section" id="date-picker-root">
      <Box sx={{
        maxWidth: '510px',
        width: '100%',
      }}>
        <Box sx={{
          position: 'relative',
          display: "flex",
          alignItems: "center"
        }}>
          <Box component="span" sx={{
            pl: "5px",
            pr: "5px",
            position: 'absolute',
            backgroundColor: "#fff",
            top: "-12px",
            left: "16px",
            zIndex: 1,
            maxWidth: "100%"
          }}>
            {label}
          </Box>
          <Box 
            id="toggleDatepicker" 
            component="span"
            onClick={toggleDatepicker}
            sx={{
              position: "absolute",
              left: 0,
              pl: "1.25rem",
              color: "rgb(107 114 128)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              zIndex: 1
            }}
          >
            <CalendarIcon />
          </Box>
          <Input
            id="date-picker-input"
            ref={datepickerInputRef}
            value={innerValue}
            onClick={toggleDatepicker}
            name={name || "date-picker-range"}
            type='text'
            slotProps={{
              input: {
                readOnly: true,
                placeholder: `${placeholder || ""}`
              },
            }}
            sx={{
              border: "2px solid transparent",
              outlineOffset: "2px",
              pr: "2rem",
              pl: "50px",
              pt: "0.625rem",
              pb: "0.625rem",
              color: "rgb(31 42 55)",
              borderColor: "rgba(0, 0, 0, 0.87)",
              borderWidth: "1px",
              borderRadius: "0.5rem",
              fontSize: "1rem",
              width: "100%",
              "&::after": {
                content: '""',
                border: 0,
              },
              "&::before": {
                content: '""',
                border: 0,
              },
              "&:hover:before": {
                content: '""',
                border: "0 !important",
              },
              height: "46px",
              '.MuiInputBase-input': {
                p: 0
              }
            }}
          />
          <Box
            sx={{
              position: "absolute",
              right: 0,
              cursor: "pointer",
              pr: "1rem",
              color: "rgb(107 114 128)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={toggleDatepicker}
          >
            <svg
              className="fill-current stroke-current"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.29635 5.15354L2.29632 5.15357L2.30055 5.1577L7.65055 10.3827L8.00157 10.7255L8.35095 10.381L13.701 5.10603L13.701 5.10604L13.7035 5.10354C13.722 5.08499 13.7385 5.08124 13.7499 5.08124C13.7613 5.08124 13.7778 5.08499 13.7963 5.10354C13.8149 5.12209 13.8187 5.13859 13.8187 5.14999C13.8187 5.1612 13.815 5.17734 13.7973 5.19552L8.04946 10.8433L8.04945 10.8433L8.04635 10.8464C8.01594 10.8768 7.99586 10.8921 7.98509 10.8992C7.97746 10.8983 7.97257 10.8968 7.96852 10.8952C7.96226 10.8929 7.94944 10.887 7.92872 10.8721L2.20253 5.2455C2.18478 5.22733 2.18115 5.2112 2.18115 5.19999C2.18115 5.18859 2.18491 5.17209 2.20346 5.15354C2.222 5.13499 2.2385 5.13124 2.2499 5.13124C2.2613 5.13124 2.2778 5.13499 2.29635 5.15354Z"
                fill="currentColor"
                stroke="currentColor"
              />
            </svg>
          </Box>
          
        </Box>

        {isOpen && (
          <Box
            id="date-picker-open"
            sx={{
              mt: 1,
              pt: "1.25rem",
              boxShadow: "0 0 #000, 0 0 #000, 0px 4px 10px rgba(0,0, 0, 0.12)",
              borderRadius: "0.75rem",
              width: "100%"
            }}>
            <Box
              id="date-picker-controls" 
              sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: "1rem",
              pl: "1.25rem",
              pr: "1.25rem",
            }}>
              <Box 
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1.5,
                  mb: 1
                }}>
                <Box 
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    color: "rgb(17, 25, 40)",
                    backgroundColor: "rgb(243, 244, 246)",
                    borderColor: "rgb(223, 228, 234)",
                    borderWidth: ".5px",
                    borderRadius: "7px",
                    ":hover": {
                      color: "rgb(255,255,255)",
                      backgroundColor: "rgb(55, 88, 249)",
                      borderColor: "rgb(55, 88, 249)"
                    }
                  }}
                  onClick={() =>
                    setCurrentDate(
                      new Date(
                        currentDate.setMonth(currentDate.getMonth() - 1),
                      ),
                    )
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.825 14.325C10.675 14.325 10.525 14.275 10.425 14.15L4.77501 8.40002C4.55001 8.17502 4.55001 7.82502 4.77501 7.60002L10.425 1.85002C10.65 1.62502 11 1.62502 11.225 1.85002C11.45 2.07502 11.45 2.42502 11.225 2.65002L5.97501 8.00003L11.25 13.35C11.475 13.575 11.475 13.925 11.25 14.15C11.1 14.25 10.975 14.325 10.825 14.325Z" />
                  </svg>
                </Box>
              </Box>
              <Typography 
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "500",
 fontSize: "1rem",
                  lineHeight: "1.25rem",
                  color: "rgb(17, 25, 40)"
                }}>
                {currentDate.toLocaleString("default", {
                  month: "long",
                })}{" "}
                {currentDate.getFullYear()}
              </Typography>
              <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: "0.5rem"
                }}>
                <Box 
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    color: "rgb(17, 25, 40)",
                    backgroundColor: "rgb(243, 244, 246)",
                    borderColor: "rgb(223, 228, 234)",
                    borderWidth: ".5px",
                    borderRadius: "7px",
                    ":hover": {
                      color: "rgb(255,255,255)",
                      backgroundColor: "rgb(55, 88, 249)",
                      borderColor: "rgb(55, 88, 249)"
                    }
                  }}
                  onClick={() =>
                    setCurrentDate(
                      new Date(
                        currentDate.setMonth(currentDate.getMonth() + 1),
                      ),
                    )
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.17501 14.325C5.02501 14.325 4.90001 14.275 4.77501 14.175C4.55001 13.95 4.55001 13.6 4.77501 13.375L10.025 8.00003L4.77501 2.65002C4.55001 2.42502 4.55001 2.07502 4.77501 1.85002C5.00001 1.62502 5.35001 1.62502 5.57501 1.85002L11.225 7.60002C11.45 7.82502 11.45 8.17502 11.225 8.40002L5.57501 14.15C5.47501 14.25 5.32501 14.325 5.17501 14.325Z" />
                  </svg>
                </Box>
              </Box>
            </Box>
            <Box
              id="date-picker-days"
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
                textTransform: "capitalize",
                fontSize: "0.9rem",
                lineHeight: "1.75rem",
                color: "rgb(99, 115, 129)",
                fontWeight: "500",
                pt: 1,
                pb: 0.5,
                pl: "1.25rem",
                pr: "1.25rem",
              }}>
              {["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"].map(
                (day) => (
                  <Box
                    key={day}
                    id={`date-picker-days-${day}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "38px",
                      width: "38px"
                    }}
                  >
                    {day}
                  </Box>
                ),
              )}
            </Box>

            <Box
              id="days-container"
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
                fontSize: "0.9rem",
                lineHeight: "1.75rem",
                fontWeight: "500",
                justifyContent: "space-between",
                pl: "1.25rem",
                pr: "1.25rem",
              }}
            >
              {renderCalendar()}
            </Box>

            <Box
              id="date-picker-buttons"
              sx={{
                display: "flex",
                p: "1.25rem 0",
                borderTop: "1px solid rgb(223, 228, 234)",
                justifyContent: "flex-end",
                mt: "1.25rem",
                gap: 2,
                pl: "1.25rem",
                pr: "1.25rem",
              }}>
              <Button
                disableRipple
                sx={{
                  border: "1px solid #3758f9",
                  color: "#3758f9",
                  borderRadius: "0.5rem",
                  fontWeight: 500,
                  fontSize: "1rem",
                  p: "0.6rem 1.25rem",
                }}
                id="cancelButton"
                onClick={handleCancel}
              >
                Borrar
              </Button>
              <Button
                sx={{
                  border: "1px solid #3758f9",
                  backgroundColor: "#3758f9",
                  color: "#fff",
                  borderRadius: "0.5rem",
                  fontWeight: 500,
                  fontSize: "1rem",
                  p: "0.6rem 1.25rem",
                  ":hover": {
                    backgroundColor: "#1B44C8"
                  },
                  "&.Mui-disabled": {
                    opacity: 0.65,
                    color: "#fff",
                    cursor: 'pointer'
                  }
                  
                }}
                disabled={!selectedStartDate && !selectedEndDate}
                id="applyButton"
                onClick={handleApply}
              >
                Aplicar
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}