import React, { ReactNode } from "react";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  SxProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  title?: string;
  handleClose: () => void;
  children: ReactNode;
  open: boolean;
  sx?: SxProps;
}
export const TableRowDetails: React.FC<Props> = ({
  children,
  handleClose,
  title,
  open,
  sx = {}
}) => {
  return (
    <Dialog
      id={`details-dialog-${title || 'untitled'}`}
      fullScreen
      sx={{
        width: { lg: "40%", md: "50%", xs: "100%" },
        left: "unset",
        right: 0,
        ...sx
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      hideBackdrop
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {title || "Detalles"}
          </Typography>
          {/* <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button> */}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};
