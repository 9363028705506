import React from "react";

interface Props {
  fill?: string;
}
export const IncreaseIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2438_745)">
        <path
          d="M11.3437 22.45H9.63508C8.78715 22.45 8.09735 21.7602 8.09735 20.9123V10.319C8.09735 9.47105 8.78715 8.78125 9.63508 8.78125H11.3437C12.1916 8.78125 12.8814 9.47105 12.8814 10.319V20.9123C12.8814 21.7602 12.1916 22.45 11.3437 22.45Z"
          fill={fill}
        />
        <path
          d="M4.85101 22.4508H3.14241C2.29448 22.4508 1.60468 21.761 1.60468 20.913V13.737C1.60468 12.889 2.29448 12.1992 3.14241 12.1992H4.85101C5.69894 12.1992 6.38874 12.889 6.38874 13.737V20.913C6.38874 21.761 5.6989 22.4508 4.85101 22.4508Z"
          fill={fill}
        />
        <path
          d="M17.8365 22.4501H16.1279C15.2799 22.4501 14.5901 21.7603 14.5901 20.9123V6.73102H13.6513C13.2123 6.73102 12.8257 6.48327 12.6422 6.08448C12.4588 5.6857 12.5223 5.23096 12.808 4.89765C12.8113 4.89385 12.8146 4.89005 12.8179 4.88633L16.4732 0.807407C16.6028 0.662731 16.7879 0.580078 16.9822 0.580078C17.1764 0.580078 17.3615 0.662731 17.4912 0.807407L21.1464 4.88633C21.1498 4.89005 21.1531 4.89385 21.1563 4.89765C21.442 5.23091 21.5055 5.6857 21.3221 6.08448C21.1387 6.48327 20.752 6.73102 20.3131 6.73102H19.3742V20.9123C19.3742 21.7603 18.6844 22.4501 17.8365 22.4501Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2438_745">
          <rect
            width="21.87"
            height="21.87"
            fill="white"
            transform="translate(0.579956 0.580078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
