import { SvgIconProps, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

interface Props extends SvgIconProps {
  value?: boolean;
}
export const ResultIcon: React.FC<Props> = ({ value, ...rest }) => {
  if (value) {
    return (
      <Tooltip title={"Valido(a)"}>
        <CheckIcon color="success" {...rest} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={"Invalido(a)"}>
      <ErrorIcon color="error" {...rest} />
    </Tooltip>
  );
};
