import { Grid, Typography } from '@mui/material';
import moment from 'moment';

interface Props {
  createdAt: string;
  updatedAt: string;
}
export const ModificationStatus: React.FC<Props> = ({
  createdAt,
  updatedAt,
}) => {
  return (
    <Grid id='mod-status' container sx={{ p: 1 }}>
      <Grid item xs={12} md={6}>
        <Typography
          sx={{ fontWeight: 500, width: 'fit-content' }}
          variant='body2'
        >
          Última modificación:{' '}
          <Typography variant='caption'>
            {moment(updatedAt).format('hh:mma DD/MM/YYYY')}
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography
          sx={{ fontWeight: 500, width: 'fit-content' }}
          variant='body2'
        >
          Fecha de creación:{' '}
          <Typography variant='caption'>
            {moment(createdAt).format('hh:mma DD/MM/YYYY')}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
