import { useCallback } from "react";

import { useAxios } from "./useAxios";
import {
  ErrorMsg,
  UpdatePasswordPayload,
  UpdatePatient,
  UpdateTherapist,
  UserType,
  LoginData,
  PostLogin,
  GoogleProfileInfoParams,
  GoogleProfileInfo,
  ErrorMsgConfig,
  RegistrationAction,
  API_BY_USER_TYPE,
  EndpointGenerator,
} from "@mapsy/shared";
import { useMapsySession } from ".";

export const useAdminAuth = () => {
  const { postData, getData, patchData, ...rest } = useAxios();
  const { token } = useMapsySession();

  const postLogin: (data: LoginData) => Promise<PostLogin> = useCallback(
    async (data) => {
      const loginEndpoint = EndpointGenerator.AuthAPI.login;
      return await postData(loginEndpoint, data, undefined, {
        404: ErrorMsg.InvalidCredentials,
      });
    },
    []
  );

  const openGoogleEndpoint = useCallback(() => {
    const loginEndpoint = EndpointGenerator.AuthAPI.googleLogin;
    window.open(loginEndpoint, "_self");
  }, []);

  const getGoogleProfile: (
    params: GoogleProfileInfoParams
  ) => Promise<GoogleProfileInfo> = useCallback(async (params) => {
    let errorConfig: ErrorMsgConfig | undefined = undefined;
    if (params.action === RegistrationAction.SignIn) {
      errorConfig = {
        404: ErrorMsg.UserNotFoundToLogin,
      };
    }

    if (params.action === RegistrationAction.SignUp) {
      errorConfig = {
        409: ErrorMsg.ExistingUser,
      };
    }

    const endpoint = EndpointGenerator.AuthAPI.googleLoginProfile;
    return await getData(endpoint, { params }, errorConfig);
  }, []);

  const getProfileInfo = useCallback(async () => {
    const profileEndpoint = EndpointGenerator.AuthAPI.profile;
    return await getData(profileEndpoint);
  }, []);

  const getLogout = useCallback(async () => {
    const logoutEndpoint = EndpointGenerator.AuthAPI.logout;
    if (!token) {
      return;
    }
    return await getData(logoutEndpoint);
  }, []);

  const getUserInfo = useCallback(async (id: string) => {
    const endpoint =
      EndpointGenerator[API_BY_USER_TYPE[UserType.Admin]].urlById(id);
    return await getData(endpoint);
  }, []);

  const updateUserInfo = useCallback(
    async ({
      _id,
      fieldsToUpdate,
    }: UpdateTherapist | UpdatePatient): Promise<string> => {
      const endpoint =
        EndpointGenerator[API_BY_USER_TYPE[UserType.Admin]].urlById(_id);
      return await patchData(endpoint, fieldsToUpdate);
    },
    [token]
  );

  return {
    ...rest,
    postLogin,
    getProfileInfo,
    getLogout,
    openGoogleEndpoint,
    getGoogleProfile,
    getUserInfo,
    updateUserInfo,
  };
};
