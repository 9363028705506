import { ReactNode, useCallback, useEffect } from "react";
import axios, { InternalAxiosRequestConfig } from "axios";

import { useAppDispatch, useMapsySession } from "../hooks";
import { useAdminAuth } from "../hooks/useAuthAdminAPI";
import {
  deleteSession,
  getProfileInfo,
  LOCAL_TOKEN_NAME,
  readLocalToken,
  startSession,
} from "../features/session/session.slice";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}

export const SessionProvider: React.FC<Props> = ({ children }) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { getLogout } = useAdminAuth();
  const { token, profileInfo, hasTokenExpired } = useMapsySession();

  const closeSession = useCallback(() => {
    nav("/users/signin");
    dispatch(deleteSession());
    getLogout();
  }, []);

  useEffect(() => {
    const local_token = localStorage.getItem(LOCAL_TOKEN_NAME);

    if (!local_token) {
      closeSession();
      return;
    }

    axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers.Authorization = `Bearer ${local_token}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(null, (error) => {
      if (error.response.status === 401) {
        closeSession();
      }
    });

    return () => {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    };
  }, [closeSession]);

  useEffect(() => {
    if (!token) {
      dispatch(readLocalToken());
      return;
    }

    if (hasTokenExpired) {
      closeSession();
      return;
    }

    if (token && !profileInfo) {
      dispatch(startSession({ access_token: token, rememberSession: true }));
      dispatch(getProfileInfo(token));
    }
  }, [token, hasTokenExpired]);

  return <>{children}</>;
};
