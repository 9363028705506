import React from "react";

interface Props {
  fill?: string;
}
export const ComissionsIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2380_3322)">
        <path
          d="M22.9608 7.16269V6.80702C22.9608 5.17201 21.6306 3.8418 19.9956 3.8418H2.96522C1.33016 3.84185 0 5.17201 0 6.80702V7.16269H22.9608Z"
          fill={fill}
        />
        <path
          d="M13.5651 16.701C13.5651 15.1629 14.0598 13.7009 14.9731 12.4961H0V16.6323C0 18.2673 1.33016 19.5975 2.96522 19.5975H14.1911C13.7813 18.6999 13.5651 17.7172 13.5651 16.701ZM11.4804 15.8023H8.92125V14.2788H11.4804V15.8023ZM3.41545 14.2788H7.39781V15.8023H3.41545V14.2788Z"
          fill={fill}
        />
        <path
          d="M16.5547 10.9721C17.7165 10.1592 19.0967 9.72128 20.5443 9.72128C21.3822 9.72128 22.1973 9.86839 22.9608 10.1496V8.68555H0V10.9721H16.5547Z"
          fill={fill}
        />
        <path
          d="M26 16.7018C26 13.6887 23.5574 11.2461 20.5443 11.2461C17.5312 11.2461 15.0885 13.6887 15.0885 16.7018C15.0885 19.7149 17.5311 22.1575 20.5443 22.1575C23.5574 22.1575 26 19.7149 26 16.7018ZM21.2797 19.6164V20.2364H20.518V20.2364V20.2364H19.7562V19.6209C19.2957 19.465 18.9189 19.1906 18.5509 18.9215L19.4503 17.6919C19.9446 18.0534 20.1976 18.2272 20.5443 18.2272C20.7403 18.2272 20.899 18.1339 20.9585 17.9838C21.0305 17.802 20.9287 17.6345 20.6861 17.5357C20.6861 17.5357 19.5973 17.1726 19.0939 16.6593C18.6715 16.2288 18.5372 15.622 18.6716 15.0447C18.807 14.4635 19.1995 14.0091 19.7562 13.7822V13.1671H21.2797V13.7567C21.6666 13.8641 21.9941 14.0229 22.1824 14.1251L21.4555 15.464C20.9736 15.2024 20.5303 15.1239 20.3577 15.1829C20.1903 15.2401 20.1649 15.3493 20.1553 15.3904C20.1417 15.4486 20.1345 15.538 20.2276 15.6418C20.3172 15.7417 21.2605 16.1248 21.2605 16.1248C22.2782 16.5391 22.7572 17.5795 22.3747 18.5449C22.176 19.0467 21.7761 19.4267 21.2797 19.6164Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2380_3322">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
