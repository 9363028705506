import React from "react";

interface Props {
  fill?: string;
}
export const SuitCaseIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2438_733)">
        <path
          d="M20.6273 5.13809H16.071V4.22681C16.071 3.22169 15.2537 2.4043 14.2485 2.4043H8.78102C7.7759 2.4043 6.95851 3.22165 6.95851 4.22681V5.13805H2.40229C1.39712 5.13809 0.579773 5.95544 0.579773 6.96057V9.69432C0.579773 10.6995 1.39712 11.5168 2.40229 11.5168H9.6923V11.0612C9.6923 10.8094 9.89609 10.6056 10.1479 10.6056H12.8817C13.1335 10.6056 13.3373 10.8094 13.3373 11.0612V11.5168H20.6273C21.6324 11.5168 22.4498 10.6995 22.4498 9.69432V6.96057C22.4498 5.95544 21.6324 5.13809 20.6273 5.13809ZM14.2485 5.13809H8.78102V4.22681H14.2485V5.13809Z"
          fill={fill}
        />
        <path
          d="M22.1971 12.0091C22.0418 11.9321 21.8563 11.9499 21.7192 12.054C21.3953 12.2992 21.0179 12.4286 20.6273 12.4286H13.3373V13.7955C13.3373 14.0474 13.1336 14.2512 12.8817 14.2512H10.148C9.89611 14.2512 9.69232 14.0474 9.69232 13.7955V12.4286H2.40235C2.01168 12.4286 1.63438 12.2992 1.31043 12.054C1.17293 11.949 0.987846 11.9312 0.832535 12.0091C0.677736 12.0861 0.579834 12.244 0.579834 12.4171V18.8074C0.579834 19.8126 1.39718 20.6299 2.40235 20.6299H20.6274C21.6325 20.6299 22.4499 19.8126 22.4499 18.8074V12.4171C22.4498 12.244 22.3519 12.0861 22.1971 12.0091Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2438_733">
          <rect
            width="21.87"
            height="21.87"
            fill="white"
            transform="translate(0.579956 0.580078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
