import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import { getTopicList } from "features/catalogs/catalogs.slice";
import { useAppDispatch, useCatalogs } from "hooks";
import { useEffect } from "react";

export const useTopics = () => {
  const dispatch = useAppDispatch();
  const {
    topics: { hash: topicsHash, loading: topicsLoading },
  } = useCatalogs();

  useEffect(() => {
    if (topicsLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(
      getTopicList({ orderBy: "longNames.es_name", order: OrderEnum.DESC })
    );
  }, [topicsLoading]);

  return {
    topicsHash,
    topicsLoading,
  };
};
