import { COLORS } from "@mapsy/shared";
import { Box, Popover, Typography } from "@mui/material";
import therapist from "assets/img/t1.png";
import { deleteSession } from "features/session/session.slice";
import { useAppDispatch, useMapsySession } from "hooks";
import { useAdminAuth } from "hooks/useAuthAdminAPI";
import { useState } from "react";

export const Header = () => {
  const dispatch = useAppDispatch();
  const { profileInfo } = useMapsySession();
  const { getLogout } = useAdminAuth();
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchor(null);
  };
  const popoverOpen = Boolean(popoverAnchor);

  const handleLogout = () => {
    dispatch(deleteSession());
    getLogout();
  };

  return (
    <Box
      id="dashboard-header-bar"
      sx={{
        flexGrow: "1",
        width: "100%",
        pt: { md: 0, sm: 8, xs: 7 },
      }}
    >
      <Box
        id="dashboard-header-container"
        component="header"
        sx={{
          my: { xs: 1, sm: 2, md: 4 },
          height: "40px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderRadius: "10px",
          backgroundColor: "#fff",
          p: 1.5,
        }}
      >
        <Box
          id="dashboard-profile-pic"
          sx={{
            borderRadius: "10px",
            width: "50px",
            height: "50px",
            backgroundColor: "#F5F7FA",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Box
            component="img"
            src={therapist}
            alt="dashboard-profile-pic"
            sx={{ width: "100%", height: "100%", borderRadius: "10px" }}
          />
        </Box>
        <Popover
          id="menu-click-open"
          open={popoverOpen}
          anchorEl={popoverAnchor}
          onClose={handleClose}
          sx={{
            ".MuiPopover-paper": {
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              py: 1,
              px: { md: 1, xs: 0 },
              display: "flex",
              flexDirection: "column",
              gap: { md: 1, xs: 0.5 },
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography sx={{ fontSize: "0.8rem", p: 1 }}>
            {profileInfo?.email}
          </Typography>
          <Typography
            sx={{
              p: 1,
              fontSize: "0.8rem",
              color: COLORS.TEXT_RED,
              cursor: "pointer",
              borderRadius: "8px",
              ":hover": {
                backgroundColor: COLORS.GREY,
              },
            }}
            onClick={handleLogout}
          >
            Cerrar sesión
          </Typography>
        </Popover>
      </Box>
    </Box>
  );
};
