import { AccountStatus } from "@mapsy/shared";

export const accountStatusFieldsEs: {
  propName: keyof AccountStatus;
  label: string;
}[] = [
  { propName: "registrationComplete", label: "Registro completo" },
  { propName: "emailVerified", label: "Correo verificado" },
  { propName: "hasProfilePic", label: "Foto de perfil" },
  { propName: "isActive", label: "Cuenta activa" },
  { propName: "professionalIdVerified", label: "Cédula verificada" },
  { propName: "phoneVerified", label: "Teléfono verificado" },
];
