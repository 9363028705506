import React, { useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CloseIcon from "@mui/icons-material/Close";

import {
  EndpointGenerator,
  ProfessionalIdValidation,
  SEPApiProfessionalIdItem,
  Therapist,
} from "@mapsy/shared";
import { useAxios } from "hooks/useAxios";
import { ResultIcon } from "components/atoms/ResultIcon";

interface Props {
  therapist: Therapist;
}
export const MexicanTherapistValidation: React.FC<Props> = ({ therapist }) => {
  const { getData, errorMsg, isLoading } = useAxios();
  const [openDialog, setOpenDialog] = useState(false);
  const [professionalIdValidation, setProfessionalIdValidation] =
    useState<ProfessionalIdValidation>();

  const handleValidation = useCallback(async () => {
    const endpoint = EndpointGenerator.TherapistAPI.validateMxTherapistById(
      therapist._id
    );
    const response: ProfessionalIdValidation = await getData(endpoint);

    setProfessionalIdValidation(response);
    setOpenDialog(true);
  }, [therapist]);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setProfessionalIdValidation(undefined);
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-dialog-title"
        aria-describedby="dialog-dialog-description"
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Validación
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          {!professionalIdValidation || isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography>Resultado general:</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  Cédula{" "}
                  {professionalIdValidation.isMajorValid &&
                  professionalIdValidation.isNameValid
                    ? "VÁLIDA"
                    : "INVÁLIDA"}
                </Typography>
              </Box>
              <Typography>
                Cédula: {professionalIdValidation.professionalId}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography>Nombre:</Typography>
                <ResultIcon value={professionalIdValidation.isNameValid} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography>Profesión:</Typography>
                <ResultIcon value={professionalIdValidation.isMajorValid} />
              </Box>
              {professionalIdValidation.externalApiResponse.sepResponse && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5">Información de la SEP</Typography>
                  {Object.keys(
                    professionalIdValidation.externalApiResponse.sepResponse
                  ).map((key, i) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>{key}: </Typography>
                      <Typography>
                        {professionalIdValidation.externalApiResponse
                          .sepResponse
                          ? (professionalIdValidation.externalApiResponse
                              .sepResponse[
                              key as keyof SEPApiProfessionalIdItem
                            ] as string)
                          : "No data"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
          {errorMsg && <Typography>{errorMsg}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Verificar cédula">
        <IconButton
          disabled={isLoading}
          sx={{ cursor: "pointer" }}
          onClick={handleValidation}
        >
          {isLoading ? <CircularProgress /> : <FactCheckIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};
