import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components/organisms/NavBar";
import { Header } from "../components/organisms/Header";

export const DashboardLayout = () => {
  return (
    <Box id="dashboard-layout" height="100%" width="100%">
      <Container maxWidth="xl" id="dashboard-container">
        <Header />
        <Box
          id="dashboard-flex"
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
          }}
        >
          <NavBar />
          <Box
            id="dashboard-content"
            sx={{
              flexGrow: "1",
              p: { md: 1 },
              ml: { md: 1 },
              borderRadius: "10px",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
