import React from "react";

interface Props {
  fill?: string;
}
export const UserIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2380_3223)">
        <path
          d="M12.3219 12.0426C13.9763 12.0426 15.4089 11.4492 16.5794 10.2785C17.75 9.10793 18.3434 7.67571 18.3434 6.02109C18.3434 4.36705 17.75 2.93463 16.5792 1.76372C15.4085 0.593374 13.9761 0 12.3219 0C10.6672 0 9.235 0.593374 8.06446 1.76391C6.89392 2.93444 6.30035 4.36686 6.30035 6.02109C6.30035 7.67571 6.89392 9.10813 8.06465 10.2787C9.23538 11.449 10.6678 12.0426 12.3219 12.0426Z"
          fill={fill}
        />
        <path
          d="M22.8579 19.2239C22.8241 18.7368 22.7558 18.2054 22.6553 17.6443C22.5538 17.0789 22.4232 16.5445 22.2668 16.056C22.1052 15.5512 21.8855 15.0526 21.6139 14.5748C21.332 14.0789 21.0009 13.6471 20.6293 13.2917C20.2408 12.92 19.7651 12.6211 19.215 12.4031C18.6668 12.1862 18.0593 12.0764 17.4095 12.0764C17.1543 12.0764 16.9075 12.1811 16.4309 12.4914C16.1375 12.6827 15.7944 12.904 15.4114 13.1487C15.0839 13.3573 14.6402 13.5528 14.0923 13.7298C13.5576 13.9028 13.0148 13.9906 12.479 13.9906C11.9432 13.9906 11.4006 13.9028 10.8654 13.7298C10.318 13.553 9.87434 13.3575 9.54723 13.1489C9.16786 12.9064 8.82454 12.6852 8.5268 12.4912C8.05073 12.1809 7.80373 12.0762 7.54852 12.0762C6.8985 12.0762 6.2912 12.1862 5.74322 12.4033C5.19352 12.6209 4.71764 12.9198 4.32873 13.2919C3.95737 13.6474 3.62606 14.0791 3.34454 14.5748C3.07312 15.0526 2.85339 15.551 2.69165 16.0562C2.53544 16.5447 2.40479 17.0789 2.30331 17.6443C2.2028 18.2046 2.13451 18.7362 2.10075 19.2245C2.06757 19.7029 2.05078 20.1993 2.05078 20.7008C2.05078 22.0058 2.46563 23.0623 3.28369 23.8414C4.09164 24.6103 5.16071 25.0003 6.46076 25.0003H18.4984C19.7985 25.0003 20.8672 24.6105 21.6753 23.8414C22.4936 23.0628 22.9084 22.0062 22.9084 20.7006C22.9082 20.1969 22.8912 19.7 22.8579 19.2239Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2380_3223">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
