import React, { useState } from "react";
import _ from 'lodash';
import {
  Slide,
  Dialog,
  Typography,
  SxProps,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { UserForm } from '../components/molecules/UserForm';
import { Entity, EntityValue, Form, FormInput } from 'interfaces/form.interface';
import { InputType } from '@mapsy/shared';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  title?: string;
  handleClose: () => void;
  handleSubmit: (filters: Entity) => void;
  open: boolean;
  filterInputs: Form;
  filterObj?: Entity;
  sx?: SxProps;
}
export const FiltersLayout: React.FC<Props> = ({
  handleClose,
  handleSubmit,
  open,
  filterInputs,
  filterObj = {},
  sx = {},
}) => {

  const [entity, setEntity] = useState(filterObj ?? {});
  const [initialFilter, _setInitialFilter] = useState(filterObj ?? {})

  return (
    <Dialog
      id="filters-dialog"
      fullScreen
      sx={{
        width: { lg: "20%", md: "50%", xs: "100%" },
        left: "unset",
        right: 0,
        ...sx
      }}
      scroll='paper'
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      hideBackdrop
    >
      <DialogTitle id="scroll-dialog-title" component='div'>
        <Box sx={{
          display: 'flex',
          gap: 2,
          alignItems: "center"
        }}>
          <CloseIcon sx={{
            ":hover": {
              cursor: "pointer"
            }
          }} 
          onClick={handleClose}/>
          <Typography variant="h4">Filtros</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <UserForm handleSubmit={() => console.log('submit')}
          inputs={filterInputs}
          entity={entity}
          isLoading={false}
          onPropertyChanged={(propertyName, value) => {
            setEntity((_entity) => ({
              ..._entity,
              [propertyName]: value,
            }));
          }}
          showSubmit={false}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setEntity(filterInputs
            .map((input: FormInput) => {
              // Vamos a iterar todos los inputs para crear un objeto
              // con valores por default
              let value: EntityValue = "";

              if (input.inputType === InputType.DateRange) {
                value = []
              } else if (input.inputType === InputType.Select) {
                value = ""
              }
              // @Todo: agregar mas default values
              
              return ({
                [input.propertyName]: value
              })
            })
            .reduce((val, acc) => ({ ...acc, ...val }), {}))

          handleClose()
        }}>Borrar Filtros</Button>
        <Button onClick={() => {
            handleSubmit(_.omitBy(entity, (value: EntityValue , _k: string) => {
              return value === '' 
                || value === null // null values
                || value === undefined  // undefined values
                // || (Array.isArray(value) && value.length === 0); // empty arrays
            }))
          }
        }>Filtrar</Button>
      </DialogActions>
    </Dialog>
  );
};
