import React from "react";

interface Props {
  fill?: string;
}
export const TransferIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2380_3209)">
        <path
          d="M5.20834 22.9173C5.20899 23.4697 5.42869 23.9992 5.81925 24.3897C6.20981 24.7803 6.73934 25 7.29167 25.0007H17.7083C18.2607 25 18.7902 24.7803 19.1808 24.3897C19.5713 23.9992 19.791 23.4697 19.7917 22.9173V22.0059H5.20834V22.9173Z"
          fill={fill}
        />
        <path
          d="M19.7917 2.08333C19.791 1.531 19.5713 1.00148 19.1808 0.610917C18.7902 0.220358 18.2607 0.00065473 17.7083 0L7.29167 0C6.73934 0.00065473 6.20981 0.220358 5.81925 0.610917C5.42869 1.00148 5.20899 1.531 5.20834 2.08333V3.125H19.7917V2.08333Z"
          fill={fill}
        />
        <path
          d="M24.7098 6.70052L21.5848 3.44531L20.0819 4.88802L21.3893 6.25H19.7917V8.33333H21.5017L20.1126 9.66459L21.554 11.1688L24.679 8.17396C24.7778 8.07926 24.857 7.96602 24.9121 7.8407C24.9671 7.71538 24.9969 7.58045 24.9997 7.44361C25.0026 7.30677 24.9784 7.17071 24.9287 7.04321C24.8789 6.91571 24.8045 6.79926 24.7098 6.70052Z"
          fill={fill}
        />
        <path
          d="M16.6667 6.24935H19.7917V4.16602H5.20834V16.666H8.33334V18.7494H5.20834V20.8327H19.7917V8.33268H16.6667V6.24935ZM15.625 10.416H11.9792C11.841 10.416 11.7086 10.4709 11.6109 10.5686C11.5132 10.6662 11.4583 10.7987 11.4583 10.9369C11.4583 11.075 11.5132 11.2075 11.6109 11.3051C11.7086 11.4028 11.841 11.4577 11.9792 11.4577H13.0208C13.6666 11.457 14.2897 11.6964 14.7689 12.1292C15.2482 12.5621 15.5495 13.1576 15.6144 13.8001C15.6793 14.4427 15.5032 15.0864 15.1202 15.6064C14.7371 16.1263 14.1746 16.4854 13.5417 16.6139V17.7077H11.4583V16.666H9.375V14.5827H13.0208C13.159 14.5827 13.2914 14.5278 13.3891 14.4301C13.4868 14.3325 13.5417 14.2 13.5417 14.0619C13.5417 13.9237 13.4868 13.7912 13.3891 13.6936C13.2914 13.5959 13.159 13.541 13.0208 13.541H11.9792C11.3334 13.5417 10.7104 13.3023 10.2311 12.8695C9.75181 12.4366 9.45047 11.8411 9.38557 11.1986C9.32067 10.556 9.49683 9.91229 9.87985 9.39233C10.2629 8.87237 10.8254 8.51328 11.4583 8.38477V7.29102H13.5417V8.33268H15.625V10.416Z"
          fill={fill}
        />
        <path
          d="M3.4983 16.6674L4.88736 15.3362L3.44596 13.832L0.320957 16.8268C0.222141 16.9215 0.142955 17.0348 0.0879269 17.1601C0.0328985 17.2854 0.00310603 17.4203 0.000252226 17.5572C-0.00260158 17.694 0.0215392 17.8301 0.0712949 17.9576C0.121051 18.0851 0.195446 18.2015 0.290228 18.3003L3.41523 21.5555L4.91809 20.1128L3.61067 18.7508H5.20833V16.6674H3.4983Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2380_3209">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
