import { FunctionComponent, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HomeIcon } from "assets/navbarIcons/HomeIcon";
import { TransferIcon } from "assets/navbarIcons/TransferIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { UserIcon } from "assets/navbarIcons/UserIcon";
import { COLORS } from "@mapsy/shared";
import { AppointmentsIcon } from "assets/navbarIcons/AppointmentsIcon";
import { ProfitsIcon } from "assets/navbarIcons/ProfitsIcon";
import { ComissionsIcon } from "assets/navbarIcons/ComissionsIcon";
import { SettingsIcon } from "assets/navbarIcons/SettingsIcon";

const navBarList: {
  Icon: FunctionComponent<{ fill?: string }>;
  label: string;
  to: string;
}[] = [
  {
    Icon: HomeIcon,
    label: "Dashboard",
    to: "/",
  },
  {
    Icon: TransferIcon,
    label: "Visitas",
    to: "/visitors",
  },
  {
    Icon: UserIcon,
    label: "Terapeutas",
    to: "/therapists",
  },
  {
    Icon: AppointmentsIcon,
    label: "Citas",
    to: "/appointments",
  },
  {
    Icon: ProfitsIcon,
    label: "Ganancias",
    to: "/profits",
  },
  {
    Icon: ComissionsIcon,
    label: "Comisiones",
    to: "/commissions",
  },
  {
    Icon: SettingsIcon,
    label: "Configuración",
    to: "/settings",
  },
];

export const NavBar = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  if (isMobile) {
    return (
      <AppBar sx={{ backgroundColor: COLORS.BLUE_1 }}>
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            sx={{
              justifyContent: { md: "space-around", xs: "flex-end" },
              gap: { md: 1, lg: 2 },
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                display: { xs: "none", md: "inherit" },
                ":hover": { cursor: "pointer" },
                fontWeight: 500,
                fontSize: { md: "2rem", lg: "2.4rem" },
              }}
              onClick={() => nav("/")}
            >
              Mapsy
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navBarList.map(({ label, to }, i) => (
                <MenuItem
                  key={`${label}-${i}`}
                  onClick={() => {
                    handleCloseNavMenu();
                    to && nav(to);
                  }}
                >
                  <Typography textAlign="center">{label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  return (
    <Box
      id="dashboard-nav-bar"
      sx={{
        flexShrink: "1",
        flexBasis: "content",
        maxWidth: "350px",
      }}
    >
      <Box
        id="navbar"
        component="nav"
        aria-label="main mailbox folders"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <List sx={{ pt: 0 }}>
          {navBarList.map(({ Icon, label, to }) => {
            const isSelected = to === pathname;
            const selectedSx = {
              "&::after": {
                position: "absolute",
                left: 0,
                top: 0,
                content: '""',
                height: "100%",
                width: "6px",
                borderRadius: "0 10px 10px 0",
                backgroundColor: COLORS.BLUE_1,
              },
              "& span": {
                fontWeight: 600,
                color: COLORS.BLUE_1,
              },
            };
            const commonSx = {
              position: "relative",
              my: 1.3,
              transition: "all 0.3s ease",
            };
            const sx: SxProps<Theme> = isSelected
              ? {
                  ...commonSx,
                  ...selectedSx,
                }
              : commonSx;

            return (
              <ListItem
                key={`navbar-item-${to}`}
                disablePadding
                sx={sx}
                onClick={() => nav(to)}
              >
                <ListItemButton sx={{ pl: 4, display: "flex", gap: 3 }}>
                  <ListItemIcon sx={{ minWidth: "0" }}>
                    <Icon fill={isSelected ? COLORS.BLUE_1 : "black"} />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};
