import React from "react";

interface Props {
  fill?: string;
}
export const ProfitsIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1189 15.3372C18.3138 15.3372 21.7267 11.8971 21.7267 7.66863C21.7267 3.44019 18.3138 0 14.1189 0C9.92392 0 6.51106 3.44014 6.51106 7.66858C6.51106 11.897 9.92392 15.3372 14.1189 15.3372ZM11.6742 10.0616C11.8955 9.72331 12.3492 9.62835 12.6875 9.84975C13.1805 10.1722 13.3664 10.2019 14.0168 10.1973C14.6517 10.1931 15.0201 9.71994 15.0937 9.28202C15.1295 9.06901 15.1432 8.54886 14.4986 8.32097C13.7424 8.05365 12.9686 7.74812 12.4302 7.32586C11.8919 6.9036 11.6454 6.17464 11.7869 5.42358C11.9404 4.60936 12.5084 3.96121 13.2692 3.73205C13.2761 3.73 13.2829 3.72835 13.2897 3.7263V3.44882C13.2897 3.04457 13.6175 2.71684 14.0217 2.71684C14.426 2.71684 14.7537 3.04457 14.7537 3.44882V3.68018C15.2509 3.79886 15.5981 4.02631 15.7391 4.13167C16.0628 4.37376 16.1291 4.83242 15.8871 5.15625C15.645 5.48009 15.1864 5.54635 14.8625 5.30421C14.7125 5.19207 14.298 4.95101 13.6915 5.13376C13.3371 5.24053 13.2452 5.59013 13.2255 5.6947C13.1868 5.9002 13.2303 6.09281 13.3337 6.17391C13.7069 6.46661 14.3786 6.72578 14.9865 6.94064C16.1073 7.33684 16.7306 8.37529 16.5374 9.5247C16.4426 10.0887 16.1589 10.6119 15.7385 10.9981C15.4521 11.2613 15.1188 11.4488 14.7537 11.556V11.8883C14.7537 12.2926 14.426 12.6203 14.0217 12.6203C13.6175 12.6203 13.2897 12.2926 13.2897 11.8883V11.629C12.8164 11.5716 12.4191 11.4236 11.886 11.0748C11.5478 10.8535 11.4529 10.3999 11.6742 10.0616Z"
        fill={fill}
      />
      <path
        d="M2.40101 17.7969H0.731986C0.327734 17.7969 0 18.1246 0 18.5289V24.2671C0 24.6714 0.327734 24.9991 0.731986 24.9991H2.40106V17.7969H2.40101Z"
        fill={fill}
      />
      <path
        d="M24.0384 17.7453C22.6665 16.3733 20.4341 16.3733 19.0622 17.7453L16.8694 19.938L15.9707 20.8367C15.6075 21.1999 15.1148 21.404 14.6012 21.404H10.231C9.83633 21.404 9.49669 21.1007 9.4781 20.7064C9.45828 20.2855 9.79368 19.938 10.2103 19.938H14.6523C15.5454 19.938 16.32 19.3021 16.4735 18.4223C16.5088 18.2203 16.5272 18.0126 16.5272 17.8006C16.5273 17.3956 16.1991 17.067 15.7942 17.067H13.3603C12.5648 17.067 11.801 16.7062 10.9922 16.3241C10.144 15.9234 9.26684 15.509 8.24123 15.4408C7.34421 15.381 6.44513 15.4793 5.56885 15.7323C4.63059 16.0032 3.95614 16.8368 3.87435 17.7992C3.87123 17.799 3.86805 17.7989 3.86488 17.7987V24.9962L16.4759 24.9996C17.3429 24.9996 18.1582 24.6619 18.7714 24.0488L24.0383 18.7819C24.3247 18.4957 24.3247 18.0316 24.0384 17.7453Z"
        fill={fill}
      />
    </svg>
  );
};
