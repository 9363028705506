import { MapsyNotificationPayload } from "@mapsy/shared";
import { env } from "../env";
import {
  useEffect,
  useState,
  createContext,
  useMemo,
  useContext,
  useRef,
  ReactNode,
  useCallback,
  Dispatch,
} from "react";

import { Manager, Socket } from "socket.io-client";
import { useMapsySession } from "../hooks";
import logger from "logger/logger";

interface SocketContextProps {
  publicSocket?: Socket;
  userSocket?: Socket;
  notifications: Record<string, MapsyNotificationPayload>;
  setNotifications: Dispatch<
    React.SetStateAction<Record<string, MapsyNotificationPayload>>
  >;
  isPublicConnected: boolean;
}

export const SocketContext = createContext<SocketContextProps | undefined>(
  undefined
);

const EVENT_NAME = "notification";

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { token, profileInfo, isLoggedIn } = useMapsySession();
  const [isPublicConnected, setPublicConnected] = useState(false);
  const [notifications, setNotifications] = useState<
    Record<string, MapsyNotificationPayload>
  >({});
  const publicSocketRef = useRef<Socket>();

  const manager = useMemo(() => {
    const socketPath = env.REACT_APP_SOCKET;
    logger.log(`Socket path: ${socketPath}`);
    return new Manager(socketPath, {
      path: "/api/ws",
      transports: ["websocket"],
      randomizationFactor: Math.random(),
      reconnectionDelay: 1500,
      reconnectionAttempts: 5,
      timeout: 1000 * 10,
    });
  }, []);

  const onNotification = useCallback((not: MapsyNotificationPayload) => {
    setNotifications((_prev) => {
      return { ..._prev, [not._id?.toString() || "unidentified"]: not };
    });
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }
    setPublicConnected(false);

    const socket = manager.socket("/admin", {
      auth: {
        token,
      },
    });
    publicSocketRef.current = socket;

    socket.on("connect", () => {
      logger.log("SocketIO: Connected and authenticated", socket.id);
      setPublicConnected(true);
    });

    socket.on("error", (msg) => {
      logger.error("SocketIO: Error", socket.id, msg);
      setPublicConnected(false);
    });

    socket.on("close", (msg) => {
      logger.error("SocketIO: Closed", socket.id, msg);
      setPublicConnected(false);
    });

    socket.on("disconnect", (msg) => {
      logger.error("SocketIO: Disconnected", socket.id, msg);
      setPublicConnected(false);
    });

    socket.on("connect_error", (reason) => {
      logger.log(`Client connect_error: ${reason}`, socket.connected);
      setPublicConnected(false);
    });

    return () => {
      if (socket.connected) {
        logger.log("SocketIO: Removing socket", socket.id);
        socket.removeAllListeners();
        socket.close();
      }
    };
  }, [manager, profileInfo, token]);

  useEffect(() => {
    if (!publicSocketRef.current?.connected) {
      return;
    }

    publicSocketRef.current.on(EVENT_NAME, onNotification);

    return () => {
      if (!publicSocketRef.current?.connected) {
        return;
      }
      publicSocketRef.current.off(EVENT_NAME);
    };
  }, [publicSocketRef.current?.connected]);

  useEffect(() => {
    if (!isLoggedIn) {
      setNotifications({});
    }
  }, [isLoggedIn]);

  return (
    <SocketContext.Provider
      value={{
        publicSocket: publicSocketRef.current,
        isPublicConnected,
        notifications,
        setNotifications,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps =>
  useContext(SocketContext) || {
    publicSocket: undefined,
    userSocket: undefined,
    isPublicConnected: false,
    notifications: {},
    setNotifications: () => {},
  };
