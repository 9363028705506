import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  text: string;
  onCopiedText?: () => void;
  textVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
}
export const CopyText: React.FC<Props> = ({
  text,
  onCopiedText,
  textVariant = "body1",
}) => {
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    setCopiedText(false);
  }, [text]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (copiedText) {
      timeout = setTimeout(() => {
        setCopiedText(false);
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [copiedText]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Tooltip
        title="Copiado"
        PopperProps={{
          disablePortal: true,
        }}
        open={copiedText}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
      >
        <Typography variant={textVariant}>{text}</Typography>
      </Tooltip>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setCopiedText(true);
          onCopiedText?.();
        }}
      >
        <IconButton size="small">
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </CopyToClipboard>
    </Box>
  );
};
