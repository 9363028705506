import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EndpointGenerator } from "@mapsy/shared";
import { LoadingEnum, ResponseError } from "@mapsy/shared";
import { ProfileInfo, PostLogin } from "@mapsy/shared";
import moment from "moment";

export interface SessionState {
  token: string;
  profileInfo?: ProfileInfo;
  isLoggedIn: boolean;
  hasTokenExpired: boolean;
  isLoading: LoadingEnum;
}

const initialState: SessionState = {
  token: "",
  profileInfo: undefined,
  isLoggedIn: false,
  hasTokenExpired: false,
  isLoading: LoadingEnum.idle,
};

export const getProfileInfo = createAsyncThunk(
  "profile/get",
  async (token: string, { rejectWithValue }) => {
    const endpoint = EndpointGenerator.AuthAPI.profile;
    try {
      const info = (await axios.get(endpoint)).data;
      return info;
    } catch (e: any) {
      const error: ResponseError = e.response?.data || {
        error: e,
        path: endpoint,
        timestamp: moment().toISOString(),
      };
      return rejectWithValue(error);
    }
  }
);

export const LOCAL_TOKEN_NAME = "mapsy_access_token";
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    readLocalToken(state: SessionState) {
      const token = localStorage.getItem(LOCAL_TOKEN_NAME);
      if (token) {
        state.token = token;
      }
    },
    startSession(
      state: SessionState,
      action: PayloadAction<PostLogin & { rememberSession: boolean }>
    ) {
      const { access_token, rememberSession } = action.payload;
      state.token = access_token;

      state.isLoggedIn = true;
      if (rememberSession) {
        localStorage.setItem(LOCAL_TOKEN_NAME, access_token);
      }
    },
    deleteSession(state: SessionState) {
      localStorage.removeItem(LOCAL_TOKEN_NAME);

      state.token = "";
      state.profileInfo = undefined;
      state.isLoggedIn = false;
      state.hasTokenExpired = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileInfo.pending, (state) => {
      state.isLoading = LoadingEnum.pending;
    });
    builder.addCase(
      getProfileInfo.fulfilled,
      (state: SessionState, action: PayloadAction<ProfileInfo>) => {
        const { payload: profileInfo } = action;
        state.isLoading = LoadingEnum.succeeded;

        if (profileInfo.exp < moment().unix()) {
          state.hasTokenExpired = true;
          return;
        }
        state.profileInfo = profileInfo;
      }
    );
    builder.addCase(
      getProfileInfo.rejected,
      (state, action: PayloadAction<unknown>) => {
        const e = action.payload as unknown as ResponseError;

        if (e.error?.statusCode === 401) {
          state.hasTokenExpired = true;
        }

        state.isLoading = LoadingEnum.failed;
      }
    );
  },
});

export const {
  readLocalToken,
  startSession,
  deleteSession,
} = sessionSlice.actions;

export default sessionSlice.reducer;
