import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import {
  getTheoriesList
} from "../../features/catalogs/catalogs.slice";
import { useAppDispatch, useCatalogs } from "../../hooks";
import { useEffect } from "react";

export const useTheories = () => {
  const dispatch = useAppDispatch();
  const {
    theories: { list: theoriesList, loading: theoriesLoading },
  } = useCatalogs();

  useEffect(() => {
    if (theoriesLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getTheoriesList({ order: OrderEnum.DESC, orderBy: "value" }));
  }, [theoriesLoading]);

  return {
    theoriesList,
    theoriesLoading,
  };
};
