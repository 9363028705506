import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "../features/session/session.slice";
import catalogsReducer from '../features/catalogs/catalogs.slice';

const store = configureStore({
  reducer: {
    sessionSlice: sessionReducer,
    catalogsSlice: catalogsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
