import React, { ReactNode, useMemo, useState } from "react";
import _ from "lodash";

import { COLORS, OrderEnum, StylesEnum, TableColumns } from "@mapsy/shared";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableHead,
  Box,
  TableSortLabel,
  LinearProgress,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { visuallyHidden } from "@mui/utils";

import { CustomButton } from "../atoms/Button";
import filters from "../../assets/svg/filters.svg";
import { FiltersLayout } from "../../layouts/FiltersLayout";
import { Entity, Form } from '../../interfaces/form.interface';

const MuiOrder: Record<OrderEnum, "asc" | "desc"> = {
  [OrderEnum.ASC]: "asc",
  [OrderEnum.DESC]: "desc",
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderEnum,
  orderBy: any
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === OrderEnum.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface Props {
  totalData: number;
  data: any[];
  columns: TableColumns;
  page: number;
  rowsPerPage: 5 | 10 | 25;
  setRowsPerPage: (rows: 5 | 10 | 25) => void;
  onNextPage: (newPage: number) => void;
  order: OrderEnum;
  setOrder: (order: OrderEnum) => void;
  orderBy: string;
  setOrderBy: (orderBy: string) => void;
  isLoading?: boolean;
  filterObj?: Entity;
  filterInputs?: Form;
  onFiltersChange?:  (filters: Entity) => void;
  onSearchChange?: (value: string) => void;
}
export const MapsySimpleTable: React.FC<Props> = ({
  data,
  totalData,
  columns,
  page,
  onNextPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  isLoading,
  filterInputs = [],
  filterObj = {},
  onFiltersChange = (_: any) => {},
  onSearchChange = (_: any) => {},
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onNextPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(+event.target.value as 5 | 10 | 25);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === OrderEnum.ASC;
    setOrder(isAsc ? OrderEnum.DESC : OrderEnum.ASC);
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    return [...data].sort(getComparator(order, orderBy));
  }, [data, order, orderBy, page, rowsPerPage]);

  return (
    <Box className="table-box-container">
      <FiltersLayout
        handleClose={() => {
          onFiltersChange?.({})
          setShowFilters(false)
        }}
        handleSubmit={(filters: Entity) => {
          onFiltersChange?.(filters)
          setShowFilters(false)
        }}
        open={showFilters}
        filterInputs={filterInputs}
        filterObj={filterObj}
        sx={{ width: { lg: "20%", md: "50%", xs: "100%" } }}
      />
      <Box
        className="table-filters-search-container"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          p: { md: 2, xs: 1 },
          gap: { xs: 1, sm: 2},
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxSizing: "border-box",
          alignItems: {sm: "center"},
          mb: 1
        }}
      >
        <FormControl sx={{
          flexGrow: 1
        }}>
          <TextField
            size="small"
            variant="outlined"
            sx={{
              borderRadius: "10px",
              backgroundColor: "#F5F7FA",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
                border: "none",
              },
            }}
            placeholder="Buscar..."
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value)
              _.debounce(onSearchChange, 300);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {!isLoading ? (
                    <SearchIcon />
                  ) : (
                    <CircularProgress size={"1rem"} />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ display: searchValue?.length > 0 ? "flex" : "none" }}
                  onClick={() => {
                    setSearchValue("")
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center"
        }}>
          <Tooltip
              title="Mostrar filtros"
            >
              <div /* Se usa div para el ref */>
                <CustomButton
                  customStyle={StylesEnum.secondary}
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{
                    display: "flex",
                    gap: { md: 1, xs: 0.5 },
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    component="img"
                    src={filters}
                    sx={{
                      width: { md: "18px", xs: "16px" },
                      cursor: "pointer",
                      color: COLORS.PURE_WHITE,
                    }}
                  />
                  <Typography sx={{
                    display: {xs: "none", sm: "block" }
                  }} variant="caption">Filtros</Typography>
                </CustomButton>
              </div>
            </Tooltip>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        className="transparent-scroll table-container"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          maxHeight: 800,
          borderRadius: "5px",
          px: { md: 4, xs: 1 },
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {isLoading && <LinearProgress />}
        <Table
          sx={{ minWidth: 300, backgroundColor: "transparent" }}
          aria-label="mapsy simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map(({ propName, title }) => (
                <TableCell
                  key={`table-heade-cell-${propName}`}
                  sx={{
                    fontSize: "0.9rem",
                    color: COLORS.BLUE_1,
                    fontWeight: 500,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === propName}
                    direction={orderBy === propName ? MuiOrder[order] : "asc"}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      handleRequestSort(propName);
                    }}
                  >
                    {title}
                    {orderBy === propName ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === OrderEnum.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, i) => (
              <TableRow key={`table-row-${i}`}>
                {columns.map(({ propName, compute }) => (
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontSize: "0.8rem", userSelect: "all" }}
                    key={`table-body-row-${row.name}-cell-${propName}`}
                  >
                    {compute ? compute(row) : _.get(row, propName, "No data")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ border: "none", p: 0 }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={totalData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  showFirstButton
                  showLastButton
                  sx={{
                    width: "100%",
                    display: "flex",
                    border: "none",
                    p: 1,
                  }}
                  component={"div"}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
