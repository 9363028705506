import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import logo from "assets/img/logotipo.webp";

import { useAdminAuth } from "hooks/useAuthAdminAPI";
import { COLORS, UserType } from "@mapsy/shared";
import { startSession } from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";

function Copyright(props: any) {
  return (
    <Typography
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mapsy.com.mx/">
        Mapsy
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({ email: "", password: "" });
  const [rememberSession, setRememberSession] = useState(false);
  const { postLogin, errorMsg } = useAdminAuth();

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = target;
      setValues((_values) => ({ ..._values, [name]: value }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const response = await postLogin({ ...values, type: UserType.Admin });

      if (response?.access_token) {
        dispatch(startSession({ ...response, rememberSession }));
        nav("/");
      }
    },
    [values, rememberSession]
  );

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url("/background.jpeg")',

          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Mapsy logo"
            style={{ width: "80px", margin: "1rem" }}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="rememberSession"
                  color="primary"
                  checked={rememberSession}
                  onChange={(e, checked) => setRememberSession(checked)}
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {errorMsg && (
              <Typography variant="subtitle2" sx={{ color: COLORS.TEXT_RED }}>
                {errorMsg}
              </Typography>
            )}
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
