import { createBrowserRouter, Navigate } from "react-router-dom";
import { SessionProvider } from "../providers/SessionProvider";
import { SocketProvider } from "../providers/SocketProvider";
import { MainLayout } from "../layouts/MainLayout";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { KPI } from "../pages/KPI";
import TitleUpdater from "../utils/titleUpdates";
import SignIn from "../pages/SignIn";
import { TherapistsPage } from "pages/TherapistsPage";
import { ImageCacheProvider } from "providers/ImageCacheProvider";
import { AppointmentsPage } from 'pages/AppointmentsPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SessionProvider>
        <SocketProvider>
          <ImageCacheProvider>
            <TitleUpdater />
            <MainLayout />
          </ImageCacheProvider>
        </SocketProvider>
      </SessionProvider>
    ),
    children: [
      {
        path: "/",
        element: <DashboardLayout />,
        children: [
          {
            element: <KPI />,
            index: true,
          },
          {
            path: "visitors",
            element: <>Visitas</>,
          },
          {
            path: "therapists",
            element: <TherapistsPage />,
          },
          {
            path: "appointments",
            element: <AppointmentsPage />,
          },
          {
            path: "profits",
            element: <>Ganancias</>,
          },
          {
            path: "commissions",
            element: <>Comisiones</>,
          },
          {
            path: "settings",
            element: <>Configuracion</>,
          },
          // Catch-all route for unmatched paths, redirects to '/' (Dashboard)
          {
            path: "*",
            element: <Navigate to="/" replace />,
          },
        ],
      },
    ],
  },
  {
    path: "/users",
    element: <MainLayout />,
    children: [
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "*",
        element: <Navigate to="/signin" replace />,
      },
    ],
  },
]);

export default router;
