import React from "react";

interface Props {
  fill?: string;
}
export const AppointmentsIcon: React.FC<Props> = ({ fill = "black" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.92074 10.2266H1.1367C0.509043 10.2266 0 10.7356 0 11.3633V23.8633C0 24.4904 0.509043 24.9994 1.1367 24.9994H3.92074C4.5484 24.9994 5.05692 24.4904 5.05692 23.8633V11.3633C5.05692 10.7356 4.5484 10.2266 3.92074 10.2266Z"
        fill={fill}
      />
      <path
        d="M10.5686 13.6348H7.78457C7.15638 13.6348 6.64787 14.1438 6.64787 14.7715V23.8619C6.64787 24.4901 7.15638 24.9986 7.78457 24.9986H10.5686C11.1963 24.9986 11.7048 24.4895 11.7048 23.8619V14.7715C11.7048 14.1438 11.1963 13.6348 10.5686 13.6348Z"
        fill={fill}
      />
      <path
        d="M17.2154 13.6348H14.4314C13.8037 13.6348 13.2952 14.1438 13.2952 14.7715V23.8619C13.2952 24.4901 13.8037 24.9986 14.4314 24.9986H17.2154C17.8436 24.9986 18.3521 24.4895 18.3521 23.8619V14.7715C18.3521 14.1438 17.8436 13.6348 17.2154 13.6348Z"
        fill={fill}
      />
      <path
        d="M23.8633 10.2266H21.0793C20.4516 10.2266 19.9431 10.7356 19.9431 11.3633V23.8633C19.9431 24.4909 20.4516 24.9994 21.0793 24.9994H23.8633C24.491 24.9994 25 24.4899 25 23.8633V11.3633C25 10.7356 24.491 10.2266 23.8633 10.2266Z"
        fill={fill}
      />
      <path
        d="M12.8314 6.8457V8.89145C13.4505 8.85049 14.1032 8.56007 14.1032 7.87868C14.1032 7.17549 13.3878 6.96964 12.8314 6.8457Z"
        fill={fill}
      />
      <path
        d="M11.0521 4.39683C11.0521 4.91385 11.4367 5.21279 12.2117 5.36811V3.51758C11.5074 3.53832 11.0521 3.95215 11.0521 4.39683Z"
        fill={fill}
      />
      <path
        d="M12.5 0C9.05372 0 6.25 2.80426 6.25 6.25C6.25 9.69468 9.05372 12.4989 12.5 12.4989C15.9463 12.4989 18.75 9.69468 18.75 6.25C18.75 2.80426 15.9463 0 12.5 0ZM12.8314 9.94628V10.5862C12.8314 10.7622 12.6963 10.9378 12.5197 10.9378C12.3452 10.9378 12.2117 10.7622 12.2117 10.5862V9.94628C10.4654 9.90372 9.59681 8.86011 9.59681 8.04362C9.59681 7.63138 9.84628 7.39309 10.2367 7.39309C11.3941 7.39309 10.4941 8.81915 12.2117 8.89096V6.73138C10.6798 6.45319 9.75213 5.78138 9.75213 4.63457C9.75213 3.22979 10.9202 2.50532 12.2117 2.46489V1.91383C12.2117 1.73777 12.3452 1.56223 12.5197 1.56223C12.6963 1.56223 12.8314 1.73777 12.8314 1.91383V2.46489C13.6367 2.48617 15.2904 2.99149 15.2904 4.00479C15.2904 4.40745 14.9894 4.64468 14.6378 4.64468C13.9654 4.64468 13.975 3.53989 12.8314 3.51862V5.48191C14.1952 5.77181 15.4032 6.17447 15.4032 7.76596C15.4032 9.15 14.3702 9.85213 12.8314 9.94628Z"
        fill={fill}
      />
    </svg>
  );
};
