import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TitleUpdater: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Mapsy Ψ - Bienvenido";
        break;
      case "/visitors":
        document.title = "Visitors - Dashboard - Mapsy Ψ";
        break;
      case "/therapists":
        document.title = "Therapists - Dashboard - Mapsy Ψ";
        break;
      case "/appointments":
        document.title = "Appointments - Dashboard - Mapsy Ψ";
        break;
      default:
        document.title = "Mapsy Ψ";
        break;
    }
  }, [location.pathname]);

  return null;
};

export default TitleUpdater;
