import moment from "moment";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  COLORS,
  EndpointGenerator,
  GetAllPagedResult,
  OrderEnum,
  TableColumns,
  Therapist,
  TherapistsOverviewInfo,
} from "@mapsy/shared";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { OverviewInfoCard } from "components/atoms/OverviewInfoCard";
import { ProfilePic } from "components/atoms/ProfilePic";
import { MapsySimpleTable } from "components/molecules/MapsySimpleTable";
import { useMapsySession } from "hooks";
import { useAxios } from "hooks/useAxios";
import { ModalLayout } from "layouts/ModalLayout";
import { SuitCaseIcon } from "assets/navbarIcons/SuitCaseIcon";
import { IncreaseIcon } from "assets/navbarIcons/IncreaseIcon";
import { TableRowDetails } from "layouts/TableRowDetailsLayout";
import { TherapistDetails } from "components/molecules/TherapistDetails";
import { ResultIcon } from "components/atoms/ResultIcon";

const LIMIT = 25;
const DEBOUNCE_TIME = 400; //time to wait before send request for regex

export const TherapistsPage = () => {
  const { getData, isLoading } = useAxios();
  const { token } = useMapsySession();

  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [therapistQueryTotal, setTherapistQueryTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.ASC);
  const [orderBy, setOrderBy] = useState<string>("createdAt");
  const [rowsPerPage, setRowsPerPage] = useState<5 | 10 | 25>(LIMIT);
  const [profilePicTherapist, setProfilePicTherapist] = useState<Therapist>();
  const [emailOrNameRegex, setEmailOrNameRegex] = useState<string>("");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [allTherapists, setAllTherapists] = useState<Therapist[]>();
  const [overviewInfo, setOverviewInfo] = useState<TherapistsOverviewInfo>({
    femaleTotal: 0,
    maleTotal: 0,
    maxAge: 0,
    minAge: 0,
    otherGenderTotal: 0,
    therapistsTotal: 0,
    avgAge: 0,
    completeRegistrationTotal: 0,
    stdDev: 0,
    femaleRatio: 0,
    maleRatio: 0,
    otherGenderRatio: 0,
  });
  const [selectedTherapist, setSelectedTherapist] = useState<Therapist>();

  const [openDetails, setOpenDetails] = useState(false);

  const fetchTherapists = useCallback(
    async ({
      page,
      order,
      orderBy,
      emailOrNameRegex,
    }: {
      page: number;
      orderBy: string;
      order: OrderEnum;
      emailOrNameRegex?: string;
    }) => {
      const endpoint = EndpointGenerator.TherapistAPI.getAll({
        orderBy,
        order,
        limit: LIMIT,
        page: page + 1,
        onlyRegistrationComplete: false,
        emailOrNameRegex,
      });
      const data: GetAllPagedResult<Therapist> = await getData(endpoint);
      if (data) {
        setTherapists(data.results);
        setTherapistQueryTotal(data.totalElements);
        if (!allTherapists) {
          //to save all the therapists only at first request
          setAllTherapists(data.results);
        }
      }
    },
    [allTherapists]
  );
  const debounceFetchTherapists = useMemo(
    () => debounce(fetchTherapists, DEBOUNCE_TIME),
    []
  );

  const fetchOverviewInfo = useCallback(async () => {
    const endpoint = EndpointGenerator.TherapistAPI.overviewInfo();
    const data: TherapistsOverviewInfo = await getData(endpoint);
    if (data) {
      setOverviewInfo(data);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchOverviewInfo();
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchTherapists({ page, order, orderBy, emailOrNameRegex });
  }, [token, page, order, orderBy]);

  useEffect(() => {
    if (!token) {
      return;
    }
    debounceFetchTherapists({ page: 0, order, orderBy, emailOrNameRegex });
    setTimeout(() => {
      setPage(0); //reinitialized pagination
    }, DEBOUNCE_TIME);
  }, [token, emailOrNameRegex]);

  const handleChangeEmailOrNameRegex = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowClearIcon(Boolean(e.target.value));
      setEmailOrNameRegex(e.target.value);
    },
    []
  );

  const handleClearEmailOrName = useCallback(() => {
    setEmailOrNameRegex("");
    setShowClearIcon(false);
  }, []);

  const tableColumns: TableColumns<Therapist> = [
    {
      title: "Nombre",
      propName: "firstName",
      compute(data) {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: { md: "0.9rem", xs: "0.8rem" },
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {`${data.firstName || "Sin nombre"} ${data.lastName || "Sin apellido"}`}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                textDecoration: "underline",
                color: COLORS.BLUE_1,
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedTherapist(data);
                setOpenDetails(true);
              }}
            >
              Ver más
            </Typography>
          </Box>
        );
      },
    },
    {
      title: "Teléfono",
      propName: "phone.number",
      compute(data) {
        return `(${data.phone.dial_code})${data.phone.number}`;
      },
    },
    {
      title: "Correo",
      propName: "email",
    },
    {
      title: "Cédula profesional",
      propName: "professionalId",
      compute(data) {
        return (
          <>
            {data.professionalId}{" "}
            <ResultIcon
              value={data.accountStatus.professionalIdVerified}
              sx={{ fontSize: "1rem" }}
            />
          </>
        );
      },
    },
    {
      title: "Foto",
      propName: "accountStatus.hasProfilePic",
      compute(data) {
        return data.accountStatus.hasProfilePic ? (
          <Typography
            sx={{
              fontSize: "0.8rem",
              textDecoration: "underline",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={(e) => setProfilePicTherapist(data)}
          >
            Ver foto
          </Typography>
        ) : (
          "No"
        );
      },
    },
    {
      title: "Registro completo",
      propName: "accountStatus.registrationComplete",
      compute(data) {
        return data.accountStatus.registrationComplete ? "Sí" : "No";
      },
    },
    {
      title: "Fecha de registro",
      propName: "createdAt",
      compute(data) {
        return moment(data.createdAt).format("DD/MM/YY hh:mma");
      },
    },
  ];

  const {
    avgAge,
    completeRegistrationTotal,
    femaleRatio,
    maleRatio,
    femaleTotal,
    maleTotal,
    maxAge,
    minAge,
    otherGenderRatio,
    otherGenderTotal,
    stdDev,
    therapistsTotal,
  } = overviewInfo;

  const handleCloseDetails = useCallback(() => {
    setOpenDetails(false);
    setSelectedTherapist(undefined);
  }, []);

  return (
    <Box>
      <TableRowDetails
        handleClose={handleCloseDetails}
        open={openDetails}
        title={
          selectedTherapist &&
          `${selectedTherapist.firstName} ${selectedTherapist.lastName}`
        }
      >
        {selectedTherapist && (
          <TherapistDetails therapist={selectedTherapist} />
        )}
      </TableRowDetails>
      <Box
        sx={{
          width: "100%",
          mb: { md: 2, xs: 1 },
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
          gap: 1,
        }}
      >
        <OverviewInfoCard title="Terapeutas" description={therapistsTotal} />
        <OverviewInfoCard
          title="Registro completo"
          description={completeRegistrationTotal}
        />
        <OverviewInfoCard
          title="Edad"
          description={`${minAge}-${maxAge}`}
          color="#FFBB38"
          Icon={SuitCaseIcon}
        />
        <OverviewInfoCard
          title="Mujeres"
          description={`${femaleTotal} (${femaleRatio.toFixed(2)}%)`}
          color="#FF82AC"
          Icon={IncreaseIcon}
        />
        <OverviewInfoCard
          title="Hombres"
          description={`${maleTotal} (${maleRatio.toFixed(2)}%)`}
          Icon={IncreaseIcon}
        />
        <OverviewInfoCard
          title="Género Otro"
          description={`${otherGenderTotal} (${otherGenderRatio.toFixed(2)}%)`}
          Icon={IncreaseIcon}
        />
      </Box>
      <Box sx={{ width: "100%", my: 1 }}>
        <Box
          sx={{
            display: "flex",
            p: { md: 2, xs: 1 },
            gap: 2,
            backgroundColor: "#fff",
            borderRadius: "18px",
            boxSizing: "border-box",
          }}
        >
          <Grid container sx={{ justifyContent: "right" }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#F5F7FA",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                      border: "none",
                    },
                  }}
                  placeholder="Buscar terapeuta"
                  value={emailOrNameRegex}
                  onChange={handleChangeEmailOrNameRegex}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {!isLoading ? (
                          <SearchIcon />
                        ) : (
                          <CircularProgress size={"1rem"} />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ display: showClearIcon ? "flex" : "none" }}
                        onClick={handleClearEmailOrName}
                      >
                        <ClearIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ my: 1 }}>
        <MapsySimpleTable
          data={therapists}
          page={page}
          totalData={therapistQueryTotal}
          columns={tableColumns}
          onNextPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isLoading={isLoading}
          filterInputs={[]}
          filterObj={{}}
          onFiltersChange={(_) => {console.log(_)}}
        />
      </Box>
      <ModalLayout
        isOpen={Boolean(profilePicTherapist)}
        onClose={() => setProfilePicTherapist(undefined)}
        maxWidthContainer="sm"
      >
        <Box sx={{ mt: 3 }}>
          {profilePicTherapist && <ProfilePic {...profilePicTherapist} />}
        </Box>
      </ModalLayout>
    </Box>
  );
};
