import moment from "moment";
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
} from "@mui/material";
import {
  Appointment,
  AppointmentStatusSpanish,
  EndpointGenerator,
  GetAllPagedResult,
  GetAppointmentsParams,
  InputType,
  OrderEnum,
  TableColumns,
} from "@mapsy/shared";
import { MapsySimpleTable } from "components/molecules/MapsySimpleTable";
import { useMapsySession } from "hooks";
import { useAxios } from "hooks/useAxios";
import { TableRowDetails } from "layouts/TableRowDetailsLayout";
import { Entity } from 'interfaces/form.interface';

const LIMIT = 25;
export const statuscita =
  Object.entries(AppointmentStatusSpanish)
    .filter(([label, _]) => isNaN(Number(label)))
    .map(([label, value]) => ({ label, value }));

export const AppointmentsPage = () => {
  const { getData, isLoading } = useAxios();
  const { token } = useMapsySession();

  const [filters, setFilters] = useState<Entity>({})
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.DESC);
  const [orderBy, setOrderBy] = useState("date");
  const [totalElements, setTotalElements] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<5 | 10 | 25>(LIMIT);  const [openDetails, setOpenDetails] = useState(false);

  const fetchData = useCallback(
    async ({
      page,
      order,
      orderBy,
      filter
    }: {
      page: number;
      orderBy: keyof Appointment | string;
      order: OrderEnum;
      filter: Entity;
    }) => {
      const params = {
        orderBy,
        order,
        limit: LIMIT,
        page: page + 1,
      } as GetAppointmentsParams<Appointment> 
      if (!_.isEmpty(filter)) {
        const { date, creationDate, appointmentStatus } = filter as { date: Array<string>, creationDate: Array<string>, appointmentStatus: number | string};
        if (date.length > 0) {
          const [startDate, endDate] = date;

          if (startDate) {
            params.fromDate = (new Date(startDate)).getTime();
          }

          if (endDate) {
            params.toDate = (new Date(endDate)).getTime();
          }
        }

        if (creationDate.length > 0) {
          const [startDate, endDate] = creationDate;

          if (startDate) {
            params.creationDateStart = startDate;
          }

          if (endDate) {
            params.creationDateEnd = endDate;
          }
        }

        if (appointmentStatus !== null && appointmentStatus !== undefined) {
          params.appointmentStatus = appointmentStatus as number
        }
      }
      
      const endpoint = EndpointGenerator.AppointmentAPI.getAll(params);
      const data: GetAllPagedResult<Appointment> = await getData(endpoint);
      if (data) {
        setAppointments(data.results);
        setTotalElements(data.totalElements)
      }
    },
    [getData]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchData({ page, order, orderBy, filter: filters });
  }, [token, page, order, orderBy, filters, fetchData]);


  const tableColumns: TableColumns<Appointment> = [
    {
      title: "Terapeuta",
      propName: "therapist",
      compute({ therapist }) {
        if (!therapist) {
          return "-";
        }

        const { firstName, lastName } = therapist;
        return `${firstName} ${lastName}`
      },
    },
    {
      title: "Paciente",
      propName: "patient",
      compute({ patient }) {
        if (!patient) {
          return "-";
        }

        const { firstName, lastName } = patient;
        return `${firstName} ${lastName}`
      },
    },
    {
      title: "Status",
      propName: "appointmentStatus",
      compute({ appointmentStatus }) {
        return `${AppointmentStatusSpanish[appointmentStatus]}`
      },
    },
    {
      title: "Fecha de Cita",
      propName: "date",
      compute({ date }) {
        return moment(date).format("DD/MM/YY hh:mma");
      },
    },
    {
      title: "Fecha de creacion",
      propName: "createdAt",
      compute({ createdAt }) {
        return moment(createdAt).format("DD/MM/YY hh:mma");
      },
    },
  ];

  const handleCloseDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);

  return (
    <Box>
      {/* @TODO Mover adentro de table */}
      <TableRowDetails
        handleClose={handleCloseDetails}
        open={openDetails}
        title={'Cita'}
      >
        Hola
      </TableRowDetails>
      <Box sx={{ my: 1 }}>
        <MapsySimpleTable
          data={appointments}
          page={page}
          totalData={totalElements}
          columns={tableColumns}
          onNextPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isLoading={isLoading}
          filterInputs={[
            {
              propertyName: "date",
              label: "Fecha de cita",
              helperText: "Fecha de cita",
              placeholder: "Elige fecha",
              inputType: InputType.DateRange,
            },
            {
              propertyName: "appointmentStatus",
              label: "Estado de Cita",
              inputType: InputType.Select,
              placeholder: "Estado de cita",
              menuItems: [{ label: "Cualquiera", value: "" }, ...statuscita],
              gridSize: {
                xs: 12,
              },
            },
            {
              propertyName: "creationDate",
              label: "Fecha de creacion",
              placeholder: "Elige fecha",
              inputType: InputType.DateRange,
          }]}
          filterObj={{ date: [], creationDate: [], appointmentStatus: "" }}
          onFiltersChange={(_filters: any) => setFilters(_filters)}
        />
      </Box>
    </Box>
  );
};
