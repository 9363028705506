import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import {
  getSchoolGradeLevelsList
} from "../../features/catalogs/catalogs.slice";
import { useAppDispatch, useCatalogs } from "../../hooks";
import { useEffect } from "react";

export const useSchoolGradeLevels = () => {
  const dispatch = useAppDispatch();
  const {
    schoolGradeLevels: { list: schoolGradeLevelsList, loading: schoolGradeLevelsLoading },
  } = useCatalogs();

  useEffect(() => {
    if (schoolGradeLevelsLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getSchoolGradeLevelsList({ order: OrderEnum.DESC, orderBy: "value" }));
  }, [schoolGradeLevelsLoading]);

  return {
    schoolGradeLevelsList,
    schoolGradeLevelsLoading,
  };
};
