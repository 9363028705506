import React from "react";
import { WorkSchedule } from "@mapsy/shared";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";
import { daysOfWeek } from "constants/daysOfWeek";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

interface Props {
  workSchedule: WorkSchedule;
}
export const WorkScheduleTable: React.FC<Props> = ({ workSchedule }) => {
  return (
    <Table sx={{ p: 1, td: { px: 0, py: 0.5 }, th: { px: 0, py: 0.5 } }}>
      <TableHead>
        <TableRow>
          <TableCell>Día</TableCell>
          <TableCell>Laboral</TableCell>
          <TableCell>Inicio</TableCell>
          <TableCell>Fin</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {daysOfWeek.map(({ label, es }, i) => (
          <TableRow key={`${label}-${i}`}>
            <TableCell>{es}</TableCell>
            <TableCell>
              {workSchedule[label].workable ? (
                <CheckIcon color="success"/>
              ) : (
                <DoNotDisturbIcon color="warning"/>
              )}
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {moment(workSchedule[label].startTime).format("HH:mm")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {moment(workSchedule[label].endTime).format("HH:mm")}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};