declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_LOGGER: "all" | "info" | "warn" | "error";
  REACT_APP_SOCKET: string;
  REACT_APP_MAPSY_URL: string
};

export const env: EnvType = { ...process.env, ...window["env"] };
