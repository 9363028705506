import React from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import {
  GenderSpanish,
  LoadingEnum,
  ModalitySpanish,
  ProvidedServiceTypesSpanish,
  Therapist,
  timezoneTranslations,
} from "@mapsy/shared";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { CopyText } from "components/atoms/CopyText";
import { ProfilePic } from "components/atoms/ProfilePic";
import { WorkScheduleTable } from "components/atoms/WorkScheduleTable";
import { env } from "env";
import { useSchoolGradeLevels } from "hooks/catalogs/useSchooGradesLevels";
import { useTheories } from "hooks/catalogs/useTheories";
import { useTopics } from "hooks/catalogs/useTopics";
import { Link } from "react-router-dom";
import { capitalizeName } from "utils/capitalize";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { accountStatusFieldsEs } from "constants/accountStatus";
import { ModificationStatus } from 'components/atoms/ModificationStatus';
import { MexicanTherapistValidation } from "./ManualTherapistValidation";

interface Props {
  therapist: Therapist;
}
export const TherapistDetails: React.FC<Props> = ({ therapist }) => {
  const mapsyURL = env.REACT_APP_MAPSY_URL || "https://mapsy.com.mx";

  const { theoriesList, theoriesLoading } = useTheories();
  const { topicsHash, topicsLoading } = useTopics();
  const { schoolGradeLevelsList, schoolGradeLevelsLoading } =
    useSchoolGradeLevels();

  const {
    _id,
    firstName,
    middleName,
    lastName,
    email,
    professionalId,
    birthDate,
    locations,
    phone,
    timezone,
    gender,
    topicsId,
    theory,
    schoolGrades,
    stars,
    professionalExperience,
    accountStatus,
    updatedAt,
    createdAt,
  } = therapist;
  return (
    <Box
      sx={{
        py: { md: 3, xs: 1 },
        px: { md: 2, xs: 1 },
        userSelect: "text",
        maxHeight: "calc(100vh - 64px)",
        overflowY: "auto",
      }}
    >
      <Grid container sx={{ py: 1 }}>
        <Grid item md={4} xs={12}>
          <ProfilePic {...therapist} />
        </Grid>
        <Grid item md={8} xs={12} sx={{ p: { md: 2, xs: 1 } }}>
          <Typography sx={{ fontWeight: 600 }}>Datos generales</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { md: 1.5, xs: 1 },
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <CopyText
                textVariant="body1"
                text={capitalizeName([firstName, middleName, lastName])}
              />
              <Link to={`${mapsyURL}/therapists/${_id}`} target="_blank">
                <Typography variant="caption">Perfil público</Typography>
              </Link>
            </Box>
            <CopyText
              textVariant="body2"
              text={`(${phone.dial_code})${phone.number}`}
            />
            <CopyText textVariant="body2" text={email} />

            <Box sx={{ display: "flex", gap: 1, width: "100%", alignItems: "center" }}>
              <Typography variant="body2">Cédula: {professionalId}</Typography>
              {therapist.locations.some((loc) => loc.country === "Mexico") && <MexicanTherapistValidation therapist={therapist} />}
            </Box>

            {theoriesLoading === LoadingEnum.pending ? (
              <CircularProgress size="14px" />
            ) : (
              <Typography variant="caption">
                {theoriesList.find((t) => t.value === theory?.toString())
                  ?.label || "Teoría no encontrada"}
              </Typography>
            )}

            <Typography variant="caption">{GenderSpanish[gender]}</Typography>
            <Typography variant="caption">
              {moment().diff(birthDate, "years")} años
            </Typography>
            <Typography variant="caption">{phone.country}</Typography>
            <Typography variant="caption">
              {timezone ? (
                <>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    Hora local:{" "}
                  </Typography>
                  {momentTz().tz(timezone).format("hh:mma")} (
                  {
                    timezoneTranslations[
                      timezone as keyof typeof timezoneTranslations
                    ]
                  }
                  )
                </>
              ) : (
                <Typography variant="caption">Sin zona horaria</Typography>
              )}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 600 }}>Áreas de atención</Typography>
          <Box sx={{ px: 1 }}>
            {topicsLoading === LoadingEnum.pending && (
              <CircularProgress size="14px" />
            )}
            {Array.isArray(topicsId) && topicsId.length ? (
              topicsId.map((id, i) => (
                <Typography variant="caption">
                  {topicsHash[id]?.longNames.es_name || "Tópico no encontrado"}
                  {i < topicsId.length - 1 ? ", " : "."}
                </Typography>
              ))
            ) : (
              <Typography variant="caption">Sin áreas de atención</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Grid container sx={{ py: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Consultorios</Typography>
        </Grid>
        {locations.map((location, i) => (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              py: { md: 2, xs: 1 },
              px: { md: 1, xs: 0 },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Consultorio {i + 1}: {ModalitySpanish[location.modality]}
            </Typography>
            <Typography variant="body2">
              {location.street}, {location.externalNo}
            </Typography>
            <Typography variant="caption">
              {location.community}, {location.city}
            </Typography>
            <Typography variant="caption">
              {location.state}, {location.country}
            </Typography>
            <Box>
              {location.providedServices.map(
                ({ serviceType, price, currency }) => (
                  <Typography variant="caption">
                    Terapia {ProvidedServiceTypesSpanish[serviceType]}: ${price}{" "}
                    ({currency})
                  </Typography>
                )
              )}
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Horarios
              </Typography>
              <WorkScheduleTable workSchedule={location.workSchedule} />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Divider />

      <Grid container sx={{ py: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Grados académicos</Typography>
        </Grid>
        <Grid item xs={12}>
          {schoolGradeLevelsLoading === LoadingEnum.pending && (
            <CircularProgress size="14px" />
          )}
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {Array.isArray(schoolGrades) && schoolGrades.length ? (
              schoolGrades.map(({ level, institution, major, year }) => (
                <ListItem sx={{ display: "list-item" }}>
                  <Typography variant="caption">
                    {schoolGradeLevelsList.find((sg) => sg.value === level)
                      ?.label || "Grado no encontrado"}{" "}
                    - {major} - {institution} - {year}
                  </Typography>
                </ListItem>
              ))
            ) : (
              <Typography variant="caption">Sin grados escolares</Typography>
            )}
          </List>
        </Grid>
      </Grid>

      <Divider />

      <Grid container sx={{ py: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Experiencia profesional
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {Array.isArray(professionalExperience) &&
            professionalExperience.length ? (
              professionalExperience.map(
                ({ position, institution, endYear, startYear }) => (
                  <ListItem sx={{ display: "list-item" }}>
                    <Typography variant="caption">
                      {position} en {institution}: {startYear}-{endYear}
                    </Typography>
                  </ListItem>
                )
              )
            ) : (
              <Typography variant="caption">
                Sin experiencia profesional
              </Typography>
            )}
          </List>
        </Grid>
      </Grid>

      <Divider />

      <Grid container sx={{ py: 1 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Cuenta</Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            py: 2,
            px: 1,
          }}
          xs={6}
          md={4}
        >
          {accountStatusFieldsEs.map(({ propName, label }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {`${label}: `}
              </Typography>
              {accountStatus[propName] ? (
                <CheckIcon color="success" />
              ) : (
                <WarningIcon color="warning" />
              )}
            </Box>
          ))}
        </Grid>
      </Grid>

      <Divider />

      <ModificationStatus updatedAt={updatedAt} createdAt={createdAt} />
    </Box>
  );
};
