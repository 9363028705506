import _ from "lodash";
import { Grid } from "@mui/material";


import { StylesEnum } from "@mapsy/shared";

import { CustomButton } from "../atoms/Button";
import { InputField } from "./InputField";
import { Entity, Form } from "../../interfaces/form.interface";
// import { useValidation } from "./providers/FormProvider";
import { useEffect } from "react";

interface Props {
  handleSubmit: () => void;
  handleBack?: () => void;
  inputs: Form;
  isLoading: boolean;
  entity: Entity;
  showSubmit?: boolean;
  showBackButton?: boolean;
  onPropertyChanged: (propertyName: string, value: any, context: any) => void;
}

export const UserForm: React.FC<Props> = ({
  handleSubmit,
  inputs,
  isLoading,
  onPropertyChanged,
  handleBack = () => {},
  showBackButton = false,
  showSubmit = true,
  entity = {},
}) => {
  // const { validateEntity } = useValidation();

  // useEffect(() => {
  //   validateEntity(entity, inputs);
  // }, []);

  return (
    <form
      id="form"
      action=" post"
      onSubmit={(e: any) => {
        e.preventDefault();
        handleSubmit();
      }}
      style={{ width: "100%" }}
    >
      <Grid
        container
        maxWidth={"lg"}
        sx={{ justifyContent: "center", rowGap: 4 }}
        id="inputs-container"
      >
        {inputs.map(({ gridSize, propertyName, ...rest }, i) => (
          <Grid
            xs={12}
            md={12}
            id={`input-${propertyName}-${i}`}
            item
            key={`input-${propertyName}-${i}`}
            sx={{ px: 1 }}
            {...gridSize}
          >
            <InputField
              backgroundMode="transparent"
              propertyName={propertyName}
              value={_.get(
                entity as Record<string, string | number>,
                propertyName,
                ""
              )}
              disabled={isLoading}
              handleChange={onPropertyChanged}
              {...rest}
            />
          </Grid>
        ))}
        <Grid
          item
          md={12}
          lg={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 6,
            flexBasis: "100%"
          }}
        >
          {showBackButton && (
            <CustomButton
              customStyle={StylesEnum.secondary}
              children={"Regresar"}
              disableRipple={true}
              isLoading={isLoading}
              onClick={handleBack}
              sx={{
                borderRadius: "14px",
                flexGrow: { xs: 1, sm: 0 }
              }}
            />
          )}

          {showSubmit &&<CustomButton
            customStyle={StylesEnum.primary}
            children={"Continuar"}
            disableRipple={true}
            isLoading={isLoading}
            sx={{
              borderRadius: "14px",
              flexGrow: { xs: 1, sm: 0 }
            }}
            type="submit"
          />}
        </Grid>
      </Grid>
    </form>
  );
};
