import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LoadingEnum,
  Catalogs,
  EndpointGenerator,
  OrderEnum,
  Topic,
} from "@mapsy/shared";
import { Catalog, GetCatalogsParams } from "@mapsy/shared";

export interface CatalogOptions {
  list: Catalog[];
  loading: LoadingEnum;
}
export interface CatalogState {
  places: CatalogOptions;
  modalities: CatalogOptions;
  theories: CatalogOptions;
  schoolGradeLevels: CatalogOptions;
  topics: { hash: { [id: string]: Topic }; loading: LoadingEnum };
}

const initialState: CatalogState = {
  places: { list: [], loading: LoadingEnum.idle },
  modalities: { list: [], loading: LoadingEnum.idle },
  theories: { list: [], loading: LoadingEnum.idle },
  schoolGradeLevels: { list: [], loading: LoadingEnum.idle },
  topics: { hash: {}, loading: LoadingEnum.idle },
};

export const getPlacesList = createAsyncThunk(
  "places/get",
  async ({ order, orderBy }: GetCatalogsParams) => {
    const endpoint = EndpointGenerator.CatalogsAPI.baseURL;
    const places: Catalog[] = (
      await axios.get(endpoint, {
        params: { order, orderBy, catalogType: Catalogs.Places },
      })
    ).data;
    return places;
  }
);

export const getModalitiesList = createAsyncThunk(
  "modalities/get",
  async ({ order, orderBy }: GetCatalogsParams) => {
    const endpoint = EndpointGenerator.CatalogsAPI.baseURL;
    const modalities: Catalog[] = (
      await axios.get(endpoint, {
        params: { order, orderBy, catalogType: Catalogs.Modalities },
      })
    ).data;
    return modalities;
  }
);

export const getTheoriesList = createAsyncThunk(
  "theories/get",
  async ({ order, orderBy }: GetCatalogsParams) => {
    const endpoint = EndpointGenerator.CatalogsAPI.baseURL;
    const theories: Catalog[] = (
      await axios.get(endpoint, {
        params: { order, orderBy, catalogType: Catalogs.Theories },
      })
    ).data;
    return theories;
  }
);

export const getSchoolGradeLevelsList = createAsyncThunk(
  "schoolGradeLevels/get",
  async ({ order, orderBy }: GetCatalogsParams) => {
    const endpoint = EndpointGenerator.CatalogsAPI.baseURL;
    const theories: Catalog[] = (
      await axios.get(endpoint, {
        params: { order, orderBy, catalogType: Catalogs.SchoolGradeLevel },
      })
    ).data;
    return theories;
  }
);

export const getTopicList = createAsyncThunk(
  "topic/get",
  async ({
    order,
    orderBy,
  }: {
    orderBy: keyof Topic | string;
    order: OrderEnum;
  }) => {
    const endpoint = EndpointGenerator.TopicAPI.addParams({ order, orderBy });
    const topics: Topic[] = (await axios.get(endpoint)).data;
    return topics;
  }
);

export const catalogsSlice = createSlice({
  name: "catalogs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPlacesList.pending, (state) => {
      state.places.loading = LoadingEnum.pending;
    });
    builder.addCase(getModalitiesList.pending, (state) => {
      state.modalities.loading = LoadingEnum.pending;
    });
    builder.addCase(getTheoriesList.pending, (state) => {
      state.theories.loading = LoadingEnum.pending;
    });
    builder.addCase(getSchoolGradeLevelsList.pending, (state) => {
      state.schoolGradeLevels.loading = LoadingEnum.pending;
    });
    builder.addCase(
      getPlacesList.fulfilled,
      (state, action: PayloadAction<Catalog[]>) => {
        state.places.list = action.payload;
        state.places.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(
      getModalitiesList.fulfilled,
      (state, action: PayloadAction<Catalog[]>) => {
        state.modalities.list = action.payload;
        state.modalities.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(
      getTheoriesList.fulfilled,
      (state, action: PayloadAction<Catalog[]>) => {
        state.theories.list = action.payload;
        state.theories.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(
      getSchoolGradeLevelsList.fulfilled,
      (state, action: PayloadAction<Catalog[]>) => {
        state.schoolGradeLevels.list = action.payload;
        state.schoolGradeLevels.loading = LoadingEnum.succeeded;
      }
    );
    builder.addCase(getPlacesList.rejected, (state) => {
      state.places.loading = LoadingEnum.failed;
    });
    builder.addCase(getModalitiesList.rejected, (state) => {
      state.modalities.loading = LoadingEnum.failed;
    });
    builder.addCase(getTheoriesList.rejected, (state) => {
      state.theories.loading = LoadingEnum.failed;
    });
    builder.addCase(getSchoolGradeLevelsList.rejected, (state) => {
      state.schoolGradeLevels.loading = LoadingEnum.failed;
    });

    builder.addCase(getTopicList.pending, (state) => {
      state.topics.loading = LoadingEnum.pending;
    });
    builder.addCase(
      getTopicList.fulfilled,
      (state, action: PayloadAction<Topic[]>) => {
        state.topics.loading = LoadingEnum.succeeded;
        action.payload.forEach((topic) => {
          state.topics.hash[topic._id] = topic;
        });
      }
    );
    builder.addCase(getTopicList.rejected, (state) => {
      state.topics.loading = LoadingEnum.failed;
    });
  },
});

export default catalogsSlice.reducer;
