import { WorkSchedule } from "@mapsy/shared";

export const daysOfWeek: { label: keyof WorkSchedule; es: string }[] = [
  { label: "monday", es: "Lunes" },
  {
    label: "tuesday",
    es: "Martes",
  },
  {
    label: "wednesday",
    es: "Miércoles",
  },
  {
    label: "thursday",
    es: "Jueves",
  },
  {
    label: "friday",
    es: "Viernes",
  },
  {
    label: "saturday",
    es: "Sábado",
  },
  {
    label: "sunday",
    es: "Domingo",
  },
];
